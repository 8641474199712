<template>
<div class="account-deletion-info">
    <b-container>
        <b-row class="justify-content-center">
            <b-col md="8" lg="6" class="">
                <div class="account-deletion-card">
                    <div class="mb-2 text-left">
                        <h3>Confirm Your Account Deletion</h3>
                        <p class="lead">Are you sure you want to delete your account? This action will permanently remove your account from our records and erase all associated data that we are not legally obligated to keep. </p>
                        
                        <p class="lead font-weight-bold"> Before proceeding:</p>
                        <p class="lead">- Understand that this process is irreversible.</p>
                        <p class="lead">- Consider backing up any important data, as you will no longer have access to your account, services, or content linked to it.</p>
                        <p class="lead">- Note that the deletion process will take immediately.</p>
                        <p class="lead">If you have any questions or require assistance, please contact our support team at <a href="mailto:support@briks.ai">support@briks.ai</a>.</p>
                        <p class="lead">To proceed with your account deletion, please complete below account verification process to initiate deletion process. You will get an email once deletion process is completed.</p>

                    </div>

                    <b-row>
                        <b-col cols="7" md="8" lg="9">
                            <b-form-group>
                                <b-form-input id="email" type="email" placeholder="Email Address" v-model='email' />
                            </b-form-group>
                        </b-col>
                        <b-col cols="5" md="4" lg="3" class="pl-0 ">
                            <b-button variant="primary" @click='sendOtp'>{{send}}</b-button>
                        </b-col>
                    </b-row>

                    <b-row v-if='otpReceived'>
                        <b-col cols="12" class="mb-25">
                            <span class="font-weight-bold">Enter the OTP</span>
                        </b-col>

                        <b-col cols="2" md="2" class="pr-0">
                            <input maxlength="1" class="form-control" v-model='otp1' />
                        </b-col>
                        <b-col cols="2" md="2" class="pr-0">
                            <input maxlength="1" class="form-control" v-model='otp2' />
                        </b-col>
                        <b-col cols="2" md="2" class="pr-0">
                            <input maxlength="1" class="form-control" v-model='otp3' />
                        </b-col>
                        <b-col cols="2" md="2" class="pr-0">
                            <input maxlength="1" class="form-control" v-model='otp4' />
                        </b-col>

                        <b-col md="12" class="mt-2">
                            <b-button variant="primary" @click='accountDeletion'>Confirm Deletion</b-button>
                            <b-button variant="danger" class="ml-1">Cancel</b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
import {
    BCard,
    BCardText,
    BButton,
    BContainer,
    BRow,
    BCol,
    BImg,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BBadge,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BCard,
        BCardText,
        BButton,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
        BImg,
        BBadge,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BFormSelect,
        ToastificationContent
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            email: '',
            send: 'Send OTP',
            otp1: '',
            otp2: '',
            otp3: '',
            otp4: '',
            otpReceived:false
        }
    },
    created() {

    },

    methods: {
        async sendOtp() {
            if (this.email == '' || this.email == null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Please enter your email address.',

                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                return false;
            } else {
                this.send = 'Sending'
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                // myHeaders.append('x-api-key', api_key);

                var urlencoded = new URLSearchParams();

                urlencoded.append("email", this.email);

                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow",
                };

                const rawResponse = await fetch(

                    process.env.VUE_APP_BASE_URL + "sentOTP",

                    requestOptions
                );

                const response = await rawResponse.json();

                this.send = 'Send OTP'
                if (response.data[0].status == 0) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data[0].message,

                            icon: 'AlertCircleIcon',

                            variant: 'danger'
                        },
                    })
                } else {
                    this.otpReceived=true
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'An OTP sent to your email address. Please enter below to continue.',

                            icon: 'CheckIcon',

                            variant: 'success'
                        },
                    })
                }

            }
        },
        async accountDeletion() {
            var myHeaders = new Headers();
            const otp = this.otp1 + this.otp2 + this.otp3 + this.otp4

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            // myHeaders.append('x-api-key', api_key);

            var urlencoded = new URLSearchParams();

            urlencoded.append("email", this.email);
            urlencoded.append("user_otp", otp);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "account-deletion",

                requestOptions
            );

            const response = await rawResponse.json();
            console.log(response)
            if (response.status == false) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: response.message,

                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
            } else {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'You account deleted successfully',

                        icon: 'CheckIcon',

                        variant: 'success'
                    },
                })
                this.$store.commit("app/SET_REMOVE_ITEMS");

                this.$store.commit("app/SET_USER", false);
                this.$router.push('/index')
            }
            //  this.$toast({
            //         component: ToastificationContent,
            //         props: {
            //             title: 

            //             icon: 'CheckIcon',

            //             variant: 'success'
            //         },
            //     })
        }
    }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 900px) and (max-width: 1440px) {
    .account-deletion-card{
        margin-bottom: 116px !important;
    }
}
@media screen and (min-width: 800px) and (max-width: 1280px) {
 .account-deletion-card{
        margin-bottom: 36px !important;
    }
}

@media screen and (min-width: 720px) and (max-width: 1280px) {
 .account-deletion-card{
        margin-bottom: 24px !important;
    }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .account-deletion-card{
        margin-bottom: 120px !important;
    }
}
</style>
